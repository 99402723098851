import { useEffect, useRef } from 'react';
import { useScroll } from 'framer-motion';
import { useLocation } from 'react-router-dom';

const useScrollToSection = () => {
  const location = useLocation();
  const lastHash = useRef('');
  const ref = useRef(null);

  const { scrollY } = useScroll({
    target: ref,
  });


  useEffect(() => {
    if (location.hash) {
      lastHash.current = location.hash.slice(1); // safe hash for further use after navigation
    }

    if (lastHash.current && document.getElementById(lastHash.current)) {
      setTimeout(() => {
        document
          .getElementById(lastHash.current)
          ?.scrollIntoView({ behavior: 'smooth', block: 'start' });
        lastHash.current = '';
      }, 100);
    }
  }, [location]);

  return {ref, scrollY};
};

export default useScrollToSection;