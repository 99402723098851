import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import './Carousel.css';

type Props = {
  images: {
    urls: string,
    alt_description: string
  }[],
}

const Caraousel = ({ images }: Props) => {
  return (
    <Swiper
      spaceBetween={0}
      slidesPerView={1}
      speed={800}
      pagination={{
        dynamicBullets: true,
      }}
      modules={[Pagination]}
    // onSlideChange={() => console.log('slide change')}
    // onSwiper={(swiper: unknown) => console.log(swiper)}
    >
      {images.map((res, index) => (<SwiperSlide key={index}>
        <img src={res.urls} alt={res.alt_description || 'photo'} loading="lazy" />
      </SwiperSlide>))
      }
    </Swiper >
  );
};

export default Caraousel;