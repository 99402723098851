import React, { useRef } from 'react';
import { motion, useScroll, useTransform, easeIn } from 'framer-motion';
import { DoubleHeartIcon } from '../../../assets/images/svg/icon';
import { sectionProps } from '../types';
import { useInvitationContext } from '../../../contexts/InvitationContext';
import { apiUrl } from '../../../utils/GLOBAL_VARIABLE';
// import { Flower2, Flower5 } from '../../../assets/images/svg/image';

const BrideSection = ({ scroll: { scrollY } }: sectionProps) => {
  const ref = useRef(null);
  const {data: {invitation}} = useInvitationContext();
  

  const { scrollYProgress } = useScroll({
    target: ref,
  });

  const opacityFlowerGroom = useTransform(
    scrollY,
    [0, 500, 760],
    [0, 0, 1]
  );

  const opacityFlowerBride = useTransform(
    scrollY,
    [0, 800, 960],
    [0, 0, 1]
  );

  const brideGroomPhotoY = useTransform(scrollYProgress, [1, 0], ['-80%', '0%'], { clamp: false, ease: easeIn });
  const brideGroomScale = useTransform(scrollYProgress, [0, 1], ['1', '1.3'], { clamp: false, ease: easeIn });
  const bridePhotoY = useTransform(scrollYProgress, [0, 1], ['0%', '80%'], { clamp: false, ease: easeIn });
  const brideScale = useTransform(scrollYProgress, [1, 0], ['1.3', '1'], { clamp: false, ease: easeIn });


  const brideGroomPhotoRotate = useTransform(
    scrollY,
    [0, 130],
    [-8, -9],
    { clamp: false }
  );

  const bridePhotoRotate = useTransform(
    scrollY,
    [0, 200],
    [12, 13],
    { clamp: false }
  );

  return (
    <section id='brideSection' className='flex flex-col relative py-24 bg-gradient-to-t from-[#ECE2D5] to-[#F1EFEA] px-5 text-[#594545] z-30'>
      <div ref={ref} className='self-center space-y-1 z-10'>
        <div className='w-[40px] h-[40px] bg-[#781444] rounded-full mx-auto flex items-center justify-center text-white'>
          <DoubleHeartIcon />
        </div>
        <h3 className='font-playFair text-3xl'>Pengantin</h3>
      </div>
      <div className='relative pt-14'>
        <motion.div
          style={{ opacity: opacityFlowerGroom }}
          className='top-[0px] right-[179px] absolute z-10 w-[137px]'
          transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1], delay: 2 }}>
          {/* <Flower2 className='rotate-[124deg]' /> */}
        </motion.div>
        <motion.div style={{ y: brideGroomPhotoY, rotate: brideGroomPhotoRotate, scale: brideGroomScale }} className='bg-white p-[16px] rounded-lg h-[280px] w-[220px] mx-auto shadow-xl relative z-10'>
          <motion.div
            initial={{ filter: 'blur(4px)', WebkitFilter: 'blur(4px)', }}
            whileInView={{ filter: 'blur(0)', WebkitFilter: 'blur(0)' }}
            className='w-full h-[80%] relative'
            transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1] }}>
            <img
              src={`${apiUrl}/${invitation?.candidatePhoto}`}
              alt='bride_and_groom'
              className='object-contain'
              loading='eager'
            />
          </motion.div>
        </motion.div>
        <div className='flex flex-col w-[250px] absolute right-0 bottom-1 text-right z-10'>
          <label>Pengantin Pria</label>
          <label className='text-3xl font-playFair bg-[#B9AC9980] w-fit ml-auto backdrop-blur-sm'>{invitation?.nameCandidate}</label>
          <label className='font-libreBaskerville text-sm mt-1 opacity-60'>Putra dari Bpk. {invitation?.fatherNameCandidate} & Ibu {invitation?.motherNameCandidate}</label>
        </div>
      </div>
      <div className='relative'>
        <motion.div
          style={{ opacity: opacityFlowerBride }}
          className='bottom-[-94px] right-[74px] absolute w-[145px]'
          transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1], delay: 2 }}>
          {/* <Flower5 className='rotate-[55deg]' /> */}
        </motion.div>
        <motion.div style={{ y: bridePhotoY, rotate: bridePhotoRotate, scale: brideScale }} className='bg-white p-[16px] rounded-lg h-[280px] w-[220px] mx-auto shadow-xl z-10'>
          <motion.div
            initial={{ filter: 'blur(4px)', WebkitFilter: 'blur(4px)', }}
            whileInView={{ filter: 'blur(0)', WebkitFilter: 'blur(0)' }}
            className='w-full h-[80%] relative'
            transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1] }}>
            <img
              src={`${apiUrl}/${invitation?.partnerPhoto}`}
              alt='bride_and_groom'
              className='object-contain'
              loading='eager'
            />
          </motion.div>
        </motion.div>
        <div className='flex flex-col w-[250px] absolute bottom-1'>
          <label>Pengantin Wanita</label>
          <label className='text-3xl font-playFair bg-[#B9AC9980] w-fit backdrop-blur-sm'>{invitation?.namePartner}</label>
          <label className='font-libreBaskerville text-sm mt-1 opacity-60'>Putri dari Bpk. {invitation?.fatherNamePartner} & Ibu {invitation?.motherNamePartner}</label>
        </div>
      </div>
    </section>
  );
};

export default BrideSection;