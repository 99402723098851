import React from 'react';
import './Loader.css';

type Props = {
  desc: string
}

const Loader = ({ desc }: Props) => (
  <div className='relative top-1/3 space-y-5'>
    <div
      className='heartbeat relative rounded-lg mx-auto'>
      <img src={'/icon.svg'} alt='icon' />
    </div>
    <p className='text-black text-center'>
      {desc}
    </p>
  </div>
);

export default Loader;