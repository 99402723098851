import React from 'react';
import { motion, useTransform } from 'framer-motion';
import { TornPaperImg } from '../../../assets/images/svg/image';
import { sectionProps } from '../types';
import { Flower1 } from '../../../assets/images/svg/image';
import { Leaf } from '../../../components/Leaf';
import { useInvitationContext } from '../../../contexts/InvitationContext';
import MarriageDateLandingPage from '../../LandingPage/components/MarriageDateLandingPage';
import { apiUrl } from '../../../utils/GLOBAL_VARIABLE';

const HomeSection = ({ scroll: { scrollY } }: sectionProps) => {
  const {data: {invitation}} = useInvitationContext();
  const nameCandidate = invitation?.nameCandidate?.split(' ');
  const namePartner = invitation?.namePartner?.split(' ');

  const opacity = useTransform(
    scrollY,
    [0, 420, 500],
    [1, 1, 0]
  );

  const scale = useTransform(
    scrollY,
    [0, 420],
    [1.5, 1.2]
  );
  

  const quotes = ` “Dan di antara tanda-tanda (kebesaran)-Nya ialah Dia menciptakan pasangan-pasangan untukmu dari jenismu sendiri,
  agar kamu cenderung dan merasa tenteram kepadanya, dan Dia menjadikan di antaramu rasa kasih dan sayang.”`;
  return (
    <section id='homeSection' className='bg-[#E6E6E6] flex h-fit flex-col relative text-[#594545] overflow-hidden '>
      <div className='w-[80%] h-[600px] absolute mx-auto left-0 right-0 top-10 bg-[#ffffffa3] rounded-[130px] before:rounded-[110px] before:border-[1px] before:border-[#A47E3B] before:w-[88%] before:h-[calc(680px-40px)] before:left-0 before:right-0 before:top-5 before:my-0 before:mx-auto before:absolute' />
      <Leaf />
      <Flower1 className='w-1/2 right-[-30px] top-[20%] rotate-[-120deg] absolute' />
      <Flower1 className='w-1/2 left-[-10px] top-[20%] rotate-[170deg] absolute' />
      <motion.div
        style={{ scale }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1], delay: 0.9 }}
        className='absolute w-[90%] top-[140px] z-20'
      >
        <img
          src={`${apiUrl}/${invitation?.groomBridePhoto}`}
          alt='bride_and_groom'
          className='object-contain w-full'
          loading='lazy'
        />
      </motion.div>
      <div className='z-30'>
        <div className='w-full h-[800px] absolute bg-gradient-to-b from-transparent from-10% to-50% to-slate-50 ' />
        <motion.div
          initial={{ y: '0%' }}
          animate={{ y: '5%' }}
          transition={{ duration: 0.6, ease: [0.45, 0, 0.55, 1] }}
          className='flex flex-col text-center space-y-5 pt-[200px] px-5 pb-36'>
          <label className='font-poppins uppercase tracking-[15px] flex flex-col pl-3'>
            <span className='text-lg'>
              Pernikahan
            </span>
          </label>
          <label className='font-playFair relative uppercase text-5xl font-bold space-x-4'>
            <span>
            {nameCandidate && nameCandidate.length > 1 ? nameCandidate[0] : 'Pengantin'}
            </span>
            <span className='absolute left-[28%] top-[-55px] text-[150px] opacity-30'>
              &
            </span>
            <span>
            {namePartner && namePartner.length > 1 ? namePartner[0] : 'Pasangan'}
            </span>
          </label>
          <div className='grid grid-cols-3 uppercase justify-center items-center text-3xl mx-auto min-w-[300px]'>
          <MarriageDateLandingPage />
          </div>
          <motion.div
            style={{ opacity }}
            className='space-y-4 flex flex-col self-center w-[360px] text-[#815B5B]' >
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 0.6 }}
              transition={{ delay: 0.6, duration: 0.3, ease: [0.45, 0, 0.55, 1] }}
              className='text-xs leading-5 font-libreBaskerville italic'>
              {
                quotes
              }
            </motion.p>
            <motion.label
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.7, duration: 0.3, ease: [0.45, 0, 0.55, 1] }}
              className='uppercase'
            >
              ( QS. Ar-Rum :21 )
            </motion.label>
          </motion.div>
        </motion.div>
      </div>
      <div className='text-[#F1EFEA] w-full z-30'>
        <TornPaperImg />
      </div>
    </section >
  );
};

export default HomeSection;