import React from 'react';
import LogoBCA from '../../assets/images/png/bca.png';
import ChipImg from '../../assets/images/png/chip.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import useCopyPaste from '../../utils/hooks/useCopyPaste';

type Props = {
  name: string;
  noAccount: string;
};

const DebitCard = ({name, noAccount}: Props) => {
  const {handleCopy} = useCopyPaste(noAccount);
  return (
    <div className='bg-gradient-to-tr from-slate-300 rounded-lg to-white p-0.5'>
      <div className='bg-gradient-to-br from-slate-200 to-slate-100 p-4 rounded-md relative overflow-hidden'>
        <div className='z-0 w-[200px] h-[200px] bg-gradient-to-bl from-[#696969] rounded-lg absolute opacity-10 left-[-40px] top-[-40px] rotate-45' />
        <div className='z-0 w-[100px] h-[100px] bg-gradient-to-bl from-[#696969] rounded-md absolute opacity-10 left-36 top-28 rotate-45' />
        <div className='relative z-10'>
          <div className='flex justify-end'>
            <img
              src={LogoBCA}
              alt='logo_bca'
              loading='lazy'
              width={80}
              className=''
            />
          </div>
          <div className='mt-4 mb-6'>
            <img
              src={ChipImg}
              alt='logo_bca'
              loading='lazy'
              width={40}
              className='mb-2'
            />
            <h4 className='text-xl'>a.n {name}</h4>
            <span>{noAccount}</span>
          </div>
          <button
            className='w-full bg-gradient-to-r from-[#781444] to-[#b33e76] text-white rounded-md p-2 flex space-x-2 justify-center items-center'
            onClick={handleCopy}
          >
            <FontAwesomeIcon icon={faClipboard} /> <span>Salin Rekening</span>
          </button>
        </div>
      </div>
    </div>
);
};

export default DebitCard;
