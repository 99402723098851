import React from 'react';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { useInvitationContext } from '../../../contexts/InvitationContext';

const MarriageDateLandingPage = () => {
  const {marriageDate} = useInvitationContext();
  return (
    <>
    <label>
      {marriageDate && format(marriageDate, 'MMM', {locale: id})}
    </label>
    <label className='flex flex-col border-x-2 border-[#594545] border-opacity-20'>
      <span className='text-sm'>
        {marriageDate && format(marriageDate, 'EEE', {locale: id})}
      </span>
      <span className='text-2xl'>
        {marriageDate && format(marriageDate, 'dd', {locale: id})}
      </span>
    </label>
    <label>
      {marriageDate && format(marriageDate, 'yyyy', {locale: id})}
    </label>
    </>
  );
};

export default MarriageDateLandingPage;