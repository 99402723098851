import React from 'react';
import { useCountdownTimer } from './hooks/useCountdown';
import { mapLabel } from '../../utils/GLOBAL_VARIABLE';

type Props = {
  date: Date
}

const CountdownTimer = ({ date }: Props) => {
  const timeRemaining = useCountdownTimer(date);

  return <div className='flex flex-col space-y-5 relative'>
    <label className=' text-white text-xs tracking-[4px] bg-[#D09EA9] w-full py-1'>
      Menuju Hari Pernikahan
    </label>
    <div className='grid grid-cols-4 gap-5 mx-5 z-20'>
      {mapLabel.map((item) => (
        <div className='flex flex-col py-1 bg-white rounded-2xl shadow-lg' key={item.key}>
          <label className='text-xl'>{timeRemaining[item.key]}</label>
          <label className='text-xs'>{item.label}</label>
        </div>
      ))}
    </div>
  </div>;
};

export default CountdownTimer;