import React from 'react';
import { GiftIcon } from '../../../assets/images/svg/icon';
import DebitCard from '../../../components/DebitCard';
import { DeviderImg } from '../../../assets/images/svg/image';
import { useInvitationContext } from '../../../contexts/InvitationContext';
import Flower2Img from '../../../assets/images/png/flower2.png';

const renderDebitCard = (item: { idEvent: string; bankName: string; accountNumber: string; cardHolderName: string; }[]) => {
  const component: JSX.Element[] = [];

  item.forEach((value: { cardHolderName: string; accountNumber: string; }, index) => {
    component.push(<DebitCard key={index} name={value.cardHolderName} noAccount={value.accountNumber} />);
  });

  return component;
};


const LoveGiftSection = () => {
  const {data: {bankAccount}} = useInvitationContext();  
  return (
  <>
    <section id='loveGiftSection' className='flex flex-col relative bg-slate-50 py-24 px-5 space-y-9 text-[#594545] z-30'>
      <div className='w-full absolute top-[-7px] left-[0.5px]'>
        <DeviderImg />
      </div>
      <img
        src={Flower2Img}
        alt='flower_top_right'
        width={250}
        loading='lazy'
        className='absolute top-[-150px] left-[-100px]'
      />
      <img
        src={Flower2Img}
        alt='flower_top_right'
        width={250}
        loading='lazy'
        className='absolute top-[-150px] right-[-100px] -scale-x-[1]'
      />
      <div className='self-center text-center space-y-1'>
        <div className='w-[40px] h-[40px] bg-[#781444] rounded-full mx-auto flex items-center justify-center text-white'>
          <GiftIcon />
        </div>
        <h3 className='font-playFair text-3xl'>Hadiah Pernikahan</h3>
        <p className='opacity-60'>Tanpa mengurangi rasa hormat, bagi anda yang ingin memberikan tanda kasih, kami sertakan informasi rekening berikut.</p>
      </div>
      <div className='flex flex-col gap-5'>
        {renderDebitCard(bankAccount)}
      </div>
    </section>
  </>
  );
};

export default LoveGiftSection;
