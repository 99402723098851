/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useRef } from 'react';
import { DoubleHeartIcon, GiftIcon, HomeIcon, PauseIcon, PictureIcon, PinLocationIcon, PlayIcon } from '../../assets/images/svg/icon';
import { Link, useLocation } from 'react-router-dom';
import useMusic from './hooks/useMusic';
import useQuery from '../../hooks/useQuery';
// import { motion } from 'framer-motion';


const menu = [
  { icon: <HomeIcon />, label: 'Home', directTo: 'homeSection' },
  { icon: <DoubleHeartIcon />, label: 'Pengantin', directTo: 'brideSection' },
  { icon: <PinLocationIcon />, label: 'Lokasi', directTo: 'locationSection' },
  { icon: <GiftIcon />, label: 'Hadiah', directTo: 'loveGiftSection' },
  { icon: <PictureIcon />, label: 'Galeri', directTo: 'gallerySection' },
];

const Navigation = () => {
  const location = useLocation();
  const { audioRef, isPlaying, setIsPlaying } = useMusic();
  const activeNow = useRef('homeSection');

  const invitationId = useQuery().get('invitation');  

  const handleButtonClick = () => {
    setIsPlaying(!isPlaying);
  };

  activeNow.current = location.hash.slice(1); // safe hash for further use after navigation]

  return (
    <div className='m-5 fixed z-50 bottom-0 w-[calc(100%-40px)] space-y-3'>
      <nav className='rounded-lg shadow-md bg-[#fffdfaac] backdrop-blur-sm md:w-[calc(450px-40px)]'>
        <div className='flex py-3 flex-row gap-3'>
          {menu.map(({ icon, label, directTo }, index) => (
            <Link to={`/BayuDanRizkia/details?invitation=${invitationId}#${directTo}`} className={`flex flex-col flex-1 items-center text-[#781444] space-y-1 ${directTo !== activeNow.current && 'opacity-40'}`} key={index}>
              <span className='text-2xl icon baseline'>{icon}</span>
              <label className='text-sm'>{label}</label>
            </Link>
          ))}
        </div>
      </nav>
      <div>
        <audio ref={audioRef} autoPlay={isPlaying} src="/music/Sezairi - It's You.mp3" />
        <button
          onClick={handleButtonClick}
          className='w-full flex flex-row justify-center items-center space-x-2 bg-[#fffdfaac] backdrop-blur-sm border text-[#781444] border-[#781444] py-2 px-4 rounded-lg self-center shadow-xl md:w-[calc(450px-40px)]'>
          {
            isPlaying ? (<><PauseIcon /> <span>Pause Music</span></>) : (<><PlayIcon /> <span>Putar Music</span></>)
          }
        </button>
      </div>
    </div>
  );
};

export default Navigation;
