import { useEffect } from 'react';

const useUnselectable = () => {
  useEffect(() => {
    // Mengakses elemen body
    const bodyElement = document.body;

    // Menetapkan atribut unselectable
    bodyElement.setAttribute('unselectable', 'on');
    bodyElement.style.userSelect = 'none'; // Untuk mendukung browser modern

    // Membersihkan atribut dan properti saat komponen di-unmount
    return () => {
      bodyElement.removeAttribute('unselectable');
      bodyElement.style.userSelect = 'auto';
    };
  }, []); // Dependency array kosong agar efek hanya dijalankan sekali saat komponen di-mount

  return null;
};

export default useUnselectable;