import React from 'react';
import { PictureIcon } from '../../../assets/images/svg/icon';
import Caraousel from '../../../components/Carousel';
import { DeviderImg } from '../../../assets/images/svg/image';

const image = [
  {
    urls: 'https://images.unsplash.com/photo-1624592338313-8fada8da6f75?w=1200&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MXwxNzQwMzQwfHxlbnwwfHx8fHw%3D',
    alt_description: 'prewedding_photos'
  },
  {
    urls: 'https://images.unsplash.com/photo-1628285438016-de9b887e4f99?w=1200&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8M3wxNzQwMzQwfHxlbnwwfHx8fHw%3D',
    alt_description: 'prewedding_photos1'
  },
  {
    urls: 'https://images.unsplash.com/photo-1638763757006-94414ab2ccf2?w=1200&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxjb2xsZWN0aW9uLXBhZ2V8MTV8MTA1NjU4NjZ8fGVufDB8fHx8fA%3D%3D',
    alt_description: 'prewedding_photos2'
  },
];


const GallerySection = () => {
  return (
    <>
      <section id='gallerySection' className='flex flex-col relative bg-slate-50 py-24 space-y-9 text-[#594545] z-30'>
      <div className='w-full absolute top-[-7px] left-[0.5px]'>
        <DeviderImg />
      </div>
        <div className='self-center space-y-1'>
          <div className='w-[40px] h-[40px] bg-[#781444] rounded-full mx-auto flex items-center justify-center text-white'>
            <PictureIcon />
          </div>
          <h3 className='font-playFair text-3xl'>Galeri</h3>
        </div>
        <Caraousel images={image} />
      </section>
    </>
  );
};

export default GallerySection;