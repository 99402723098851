import React from 'react';
import { AnimatePresence } from 'framer-motion';
import HomeSection from './HomeSection/HomeSection';
import BrideSection from './BrideSection/BrideSection';
import LocationSection from './LocationSection/LocationSection';
import GallerySection from './GallerySection/GallerySection';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';

import useScrollToSection from './hooks/useScrollToSection';
import useGetInvitationData from '../../utils/hooks/useGetInvitationData';
import LoveGiftSection from './LoveGiftSection';

const InvitationPage = () => {
  const {ref, scrollY} = useScrollToSection();

  useGetInvitationData();

  return (
    <>
      <Navigation />

      <AnimatePresence mode='sync'>
        <HomeSection key="home-section" reference={ref} scroll={{ scrollY: scrollY }} />
        <BrideSection key='bride-section' reference={ref} scroll={{ scrollY: scrollY }} />
        <LocationSection key='location-section' reference={ref} scroll={{ scrollY: scrollY }} />
        <LoveGiftSection key='love-gift-section' />
        <GallerySection key='gallery-section' />
      </AnimatePresence>
      <Footer />
    </>
  );
};

export default InvitationPage;