import { useCallback, useEffect, useMemo } from 'react';
import { useInvitationContext } from '../../contexts/InvitationContext';
import { getBankAccountData, getInvitationData } from '../../services/InvitationServices';
import useQuery from '../../hooks/useQuery';

const isValidDate = (date: Date) => {
  return date instanceof Date && !isNaN(date.getTime());
};

export const parseDate = (dateStr: string | undefined, timeStr: string | undefined) => {
  if (!dateStr || !timeStr) return null;

  const dateParts = dateStr.split('-');
  const timeParts = timeStr.split(':');

  if (dateParts.length !== 3 || timeParts.length !== 2) return null;

  const [day, month, year] = dateParts;
  const [hour, minute] = timeParts;

  // Construct the date object
  const date = new Date(
    Number(year),
    Number(month) - 1,
    Number(day),
    Number(hour),
    Number(minute)
  );

  // Validate the date
  return isValidDate(date) ? date : null;
};

const useGetInvitationData = () => {
  const invitationId = useQuery().get('invitation');  
  
  const memoizedInvitationId = useMemo(() => invitationId, [invitationId]);
  const { data, setData } = useInvitationContext();
  

  const fetchData = useCallback(
    async () => {
      if (memoizedInvitationId) {
      const resultInvitation = await getInvitationData(invitationId);
      const resultBankAccount = await getBankAccountData(invitationId);      
      
      setData((prevState) => ({
        ...prevState,
        bankAccount: resultBankAccount?.result,
        invitation: resultInvitation?.result
      }));
      }
    },
    [memoizedInvitationId, invitationId, setData],
  );

  useEffect(() => {
    fetchData();

    return () => {};
  }, [fetchData]);

  return {data};
};

export default useGetInvitationData;