import React from 'react';
import { motion, useTransform } from 'framer-motion';
import { FlowerVertical, TornPaperImg } from '../../../assets/images/svg/image';
import { CalendarIcon, ClockIcon, MapIcon, PinLocationIcon } from '../../../assets/images/svg/icon';
import useMetadata from '../../../hooks/useMetadata';
import { sectionProps } from '../types';
import { Link } from 'react-router-dom';
import { useInvitationContext } from '../../../contexts/InvitationContext';
import { format } from 'date-fns';
import { id } from 'date-fns/locale';
import { parseDate } from '../../../utils/hooks/useGetInvitationData';

const LocationSection = ({ reference, scroll: { scrollY } }: sectionProps) => {

  const opacity = useTransform(
    scrollY,
    [1250, 1300],
    [0, 1]
  );

  const { isLoading } = useMetadata({ title: 'Bayu and Rizkia - inviteesSibayu.id', loadingTime: 3.5 });
  const {data: {invitation}} = useInvitationContext();

  const ceremonyStartDate = parseDate(invitation?.marriageCeremonyDate, invitation?.marriageStartTime);
  const ceremonyEndDate = parseDate(invitation?.marriageCeremonyDate, invitation?.marriageEndTime);
  const receptionStartDate = parseDate(invitation?.weddingReceptionDate, invitation?.weddingStartTime);
  const receptionEndDate = parseDate(invitation?.weddingReceptionDate, invitation?.weddingEndTime);
  
  const now = new Date();  
  
  const dateActive = (start: Date | null, end: Date | null, type: 'ceremony' | 'reception') => {
    
    if (!start && !end) {
      return;
    }

    if (type === 'ceremony' && start && now.getTime() < start?.getTime()) {
      return 'card-active card-comming-soon';
    }

    if (start && end && (now.getTime() >= start?.getTime() && now.getTime() <= end?.getTime())) {
      return 'card-active card-on-going';
    }

    if (end && now.getTime() > end?.getTime()) {
      return 'opacity-50';
    }

    return;
  };


  return (
    <section id='locationSection' className='flex flex-col relative bg-slate-50 text-[#594545] overflow-hidden z-30'>
      <div className='text-[#ECE2D5] rotate-180 w-full'>
        <TornPaperImg />
      </div>
      <div className='py-24 px-5 flex flex-col space-y-9'>
        <div className='self-center space-y-1' ref={reference}>
          <div className='w-[40px] h-[40px] bg-[#781444] rounded-full mx-auto flex items-center justify-center text-white'>
            <PinLocationIcon />
          </div>
          <h3 className='font-playFair text-3xl'>Waktu & Lokasi</h3>
        </div>
        <div className='space-y-5'>
          {/* <Flower2 className='absolute w-[200px] top-[190px] rotate-[145deg] right-[10px] opacity-20 z-0' />
          <Flower2 className='absolute w-[200px] top-[180px] rotate-[206deg] left-[10px] opacity-20 z-0 scale-x-[-1]' /> */}

          <div className='font-libreBaskerville'>
            <motion.div
              initial={{ marginTop: '10%', opacity: 0 }}
              whileInView={{ marginTop: '0%', opacity: ceremonyEndDate && now.getTime() > ceremonyEndDate?.getTime() ? 0.5 : 1 }}
              transition={{ delay: 0.3, duration: 0.6, ease: [0.45, 0, 0.55, 1] }}
              viewport={{ once: true }}
              className={`px-[16px] py-[30px] rounded-lg w-full border-2 border-[#594545] border-opacity-10 mx-auto text-center space-y-1 shadow-sm relative ${dateActive(ceremonyStartDate, ceremonyEndDate, 'ceremony')}`}>

              <h4 className='font-playFair text-xl'>Akad</h4>
              <div className='flex flex-col items-center opacity-60 text-md'>
                <div className='flex items-center space-x-2'>
                  <CalendarIcon />
                  <label>
                    {ceremonyStartDate && format(ceremonyStartDate, 'EEEE, dd MMMM yyyy', {locale: id})}
                  </label>
                </div>
                <div className='flex items-center space-x-2'>
                  <ClockIcon />
                  <label>
                    {invitation?.marriageStartTime} - {invitation?.marriageEndTime}
                  </label>
                </div>
              </div>
            </motion.div>
            <motion.div
              initial={{ marginTop: '10%', opacity: 0 }}
              whileInView={{ marginTop: '3%', opacity: receptionEndDate && now.getTime() > receptionEndDate?.getTime() ? 0.5 : 1  }}
              transition={{ delay: 0.7, duration: 0.6, ease: [0.45, 0, 0.55, 1] }}
              viewport={{ once: true }}
              className={`px-[16px] py-[30px] rounded-lg w-full border-2 border-[#594545] border-opacity-10 mx-auto text-center space-y-1 shadow-sm relative ${dateActive(receptionStartDate, receptionEndDate, 'reception')}`}>
              <h4 className='font-playFair text-xl'>Resepsi</h4>
              <div className='flex flex-col items-center opacity-60 text-md'>
                <div className='flex items-center space-x-2'>
                  <CalendarIcon />
                  <label>
                  {receptionStartDate && format(receptionStartDate, 'EEEE, dd MMMM yyyy', {locale: id})}
                  </label>
                </div>
                <div className='flex items-center space-x-2'>
                  <ClockIcon />
                  <label>
                    {invitation?.weddingStartTime} - {invitation?.weddingEndTime}
                  </label>
                </div>
              </div>
            </motion.div>
          </div>
          <div className='relative'>
            <hr className='absolute top-0 bottom-0 my-auto w-full z-0' />
            <FlowerVertical className='mx-auto pb-[10px] relative z-10 w-[160px] h-auto' />
          </div>
          <motion.div
            style={{ opacity }}
            className='space-y-4 z-20'>
            {
              isLoading
                ? (<div className='bg-[#8b56a02b] w-[80%] h-[150px] animate-pulse shadow-lg rounded-lg mx-auto ' />)
                : (

                  <iframe
                    src={`//maps.google.com/maps?q=${invitation?.marriageCeremonyCoordinate}&z=15&output=embed`}
                    width="80%"
                    height="150px"
                    loading="lazy"
                    className='shadow-lg rounded-lg mx-auto relative'
                  />)
            }
            <div className='text-center space-y-1'>
              <h2 className='text-2xl font-poppins'>{invitation?.marriageCeremonyBuilding}</h2>
              <p className='opacity-60 text-xs font-libreBaskerville'>{invitation?.marriageCeremonyLocation}</p>
            </div>
          </motion.div>
          <Link to={`https://www.google.com/maps/place/${invitation?.marriageCeremonyCoordinate}`} target="_blank" rel="noopener noreferrer">
            <motion.button
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ delay: 1, duration: 0.6, ease: [0.45, 0, 0.55, 1] }}
              viewport={{ once: true }}
              className='w-full mt-8 flex flex-row justify-center items-center space-x-2 transition ease-in-out duration-300 bg-gradient-to-r from-[#781444] to-[#b33e76] text-white py-2 px-4 rounded-lg self-center shadow-xl hover:bg-[#781444] hover:shadow-md'>
              <MapIcon /> <span>Lihat Peta</span>
            </motion.button>
          </Link>
        </div>
      </div>
    </section >
  );
};

export default LocationSection;