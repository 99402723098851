import { useEffect, useRef, useState } from 'react';


const useMusic = () => {
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const audioRef = useRef<HTMLAudioElement | null>(null);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //       if (document.hidden) {
  //         // Page is now hidden, pause the audio
  //         return setIsPlaying(false);
  //       }
        
  //       // Page is now visible, resume or start the audio
  //       return setIsPlaying(true);
  //   };

  //   // Listen for visibility changes
  //   document.addEventListener('visibilitychange', handleVisibilityChange);

  //   // Clean up the event listener when the component is unmounted
  //   return () => {
  //     document.removeEventListener('visibilitychange', handleVisibilityChange);
  //   };
  // }, [isPlaying]);

  useEffect(() => {
    const initializeAudio = () => {
      const audio = audioRef.current;

      if (audio && isPlaying) {
        const playPromise = audio.play();

        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Audio successfully started playing
            })
            .catch((error) => {
              console.error('Error playing audio:', error);
            });
        }
      }

      if (audio && !isPlaying) {
        audio.pause();
      }
    };

    initializeAudio();

  }, [isPlaying]);

  return {
    audioRef,
    isPlaying,
    setIsPlaying
  };
};

export default useMusic;