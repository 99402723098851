import { faGithubSquare, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faHeart } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const mail = 'mailto:bayuridhoalkhifli@gmail.com';
  const instagram = 'https://www.instagram.com/ridhoalkhifli/';
  const github = 'https://github.com/BayuRidhoAlkhifli';
  const linkedin = 'https://www.linkedin.com/in/bayu-ridho-alkifli-33b034143';

  return (
    <footer className="text-center footer pt-20 pb-44 space-y-4">
      <label className="title-vb title-big-prj-item">Get In Touch</label>
      <div>
        <Link to={mail} className="btn ml-1 btn-transparent hover:color-blue" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faEnvelope} />
        </Link>
        <Link to={instagram} className="btn ml-1 btn-transparent hover:color-blue" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faInstagramSquare} />
        </Link>
        <Link to={github} className="btn ml-1 btn-transparent hover:color-blue" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faGithubSquare} />
        </Link>
        <Link to={linkedin} className="btn ml-1 btn-transparent hover:color-blue" target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faLinkedin} />
        </Link>
      </div>
      <div className="txt-color-06">
        <span className="d-block">&copy; 2023 siBayu </span>
        <span className="d-block">Made with <FontAwesomeIcon icon={faHeart} /> in Jakarta, Indonesia</span>
      </div>
    </footer>
  );
};

export default Footer;