import React, { Dispatch, SetStateAction, useContext, useMemo, useState } from 'react';
import { InvitationContextProps, TypeDataInvitation } from './types';
import { initialState } from '../utils/GLOBAL_VARIABLE';
import { parseDate } from '../utils/hooks/useGetInvitationData';

type InvitationContextValue = {
  data: TypeDataInvitation
  setData: Dispatch<SetStateAction<TypeDataInvitation>>
  marriageDate: Date | null;
}

const invitationDefaultVal: InvitationContextValue = {
  data: initialState,
  setData: () => { },
  marriageDate: null,
};

const InvitationContext = React.createContext(invitationDefaultVal);

export const useInvitationContext = () => {
  return useContext(InvitationContext);
};

const InvitationProvider = ({ children }: InvitationContextProps) => {
  const [data, setData] = useState<TypeDataInvitation>(invitationDefaultVal.data);

  const marriageDate = useMemo(() => parseDate(data?.invitation?.marriageCeremonyDate, data?.invitation?.marriageStartTime), [data.invitation]);  


  return (
    <InvitationContext.Provider value={{ data, setData, marriageDate}}>
      {children}
    </InvitationContext.Provider>
  );
};

export default InvitationProvider;
