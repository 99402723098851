import React from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import CountdownTimer from './CountdownTimer';
import { Link } from 'react-router-dom';
import { MailOpenedIcon } from '../../assets/images/svg/icon';
import { FlowerVertical } from '../../assets/images/svg/image';
import Flower1Img from '../../assets/images/png/flower1.png';
import Flower2Img from '../../assets/images/png/flower2.png';
import Loader from '../../components/Loader';
import useMetadata from '../../hooks/useMetadata';
import TextGuest from './components/TextGuest';
import MarriageDateLandingPage from './components/MarriageDateLandingPage';
import { useInvitationContext } from '../../contexts/InvitationContext';
import useQuery from '../../hooks/useQuery';
import useGetInvitationData from '../../utils/hooks/useGetInvitationData';

const LandingPage = () => {
  const { isLoading } = useMetadata({ title: 'Bayu and Rizkia - inviteesSibayu.id', loadingTime: 2 });

  const {data: {invitation}} = useGetInvitationData();
  const invitationId = useQuery().get('invitation');  
  const {marriageDate} = useInvitationContext();  

  const nameCandidate = invitation?.nameCandidate?.split(' ');
  const namePartner = invitation?.namePartner?.split(' ');
  

  return (
    <AnimatePresence>
      <main className='h-screen min-h-[750px] bg-[#E6E6E6] relative overflow-hidden'>
        {isLoading ?
          <Loader desc='Tunggu Sebentar..' />
          :
          <section className='flex items-center h-full text-[#594545] bg-[#E6E6E6]'>
            <div className='flex max-w-[450px] mx-auto justify-center'>
              <div className='flex flex-col uppercase text-center'>
                <motion.div
                  initial={{ scale: 0, zIndex: 20 }}
                  animate={{ scale: 0.5 }}
                  transition={{ duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                >
                  <img
                    src={Flower1Img}
                    alt='flower_top_right'
                    loading='lazy'
                    className='absolute top-[-180px] right-[-230px] rotate-[132deg] z-20'
                  />
                </motion.div>
                <div className='bg-[#ffffffa3] before:border-[1px] before:border-[#A47E3B] backdrop-blur-sm rounded-[130px] before:rounded-[110px] w-[360px] before:w-[calc(360px-40px)] h-[680px] before:h-[calc(680px-40px)] before:left-0 before:right-0 before:my-0 before:mx-auto flex before:absolute flex-col self-center space-y-5 justify-center z-10'>
                  <FlowerVertical />
                  <label className='font-poppins tracking-[15px] flex flex-col pl-3'>
                    <span className='text-sm'>
                      Undangan
                    </span>
                    <span className='text-lg'>
                      Pernikahan
                    </span>
                  </label>
                  <label className='relative font-playFair text-5xl font-bold space-x-4'>
                    <span>
                      {nameCandidate && nameCandidate.length > 1 ? nameCandidate[0] : 'Pengantin'}
                    </span>
                    <span className='absolute left-[25%] top-[-55px] text-[150px] opacity-30'>
                      &
                    </span>
                    <span>
                      {namePartner && namePartner.length > 1 ? namePartner[0] : 'Pasangan'}
                    </span>
                  </label>
                  <div className='grid grid-cols-3 justify-center items-center text-3xl mx-auto min-w-[300px]'>
                    <MarriageDateLandingPage />
                  </div>
                  {marriageDate && <CountdownTimer date={marriageDate} />}
                  <div className='flex flex-col text-center relative left-0 right-0 top-5 p-5 mx-auto z-20'>
                    <TextGuest />
                    <Link
                      to={`/BayuDanRizkia/details?invitation=${invitationId}#homeSection`}
                      className='w-fit flex flex-row space-x-2 mt-5 transition ease-in-out duration-300 bg-gradient-to-r from-[#781444] to-[#b33e76] text-white py-2 px-4 rounded-lg self-center shadow-xl hover:bg-[#5e0c33] hover:shadow-md'>
                      <MailOpenedIcon /> <span>Buka Undangan</span>
                    </Link>
                  </div>
                  <motion.div
                    initial={{ scale: 0 }}
                    animate={{ scale: 0.8 }}
                    transition={{ duration: 0.5, ease: [0.45, 0, 0.55, 1] }}
                  >
                    <img
                      src={Flower2Img}
                      alt='flower_top_right'
                      loading='lazy'
                      className='absolute bottom-[-160px] left-[-60px] rotate-[-15deg]'
                    />
                  </motion.div>
                </div>
              </div>

            </div>
          </section>
        }
      </main>
    </AnimatePresence>
  );
};

export default LandingPage; 