import React from 'react';
import { useInvitationContext } from '../../../contexts/InvitationContext';


const TextGuest = () => {
  const { data: {invitation} } = useInvitationContext();
  return (
    <>
      <label className='text-md font-libreBaskerville'>
        Teruntuk
      </label>
      <label className='text-xl font-bold'>
        {invitation?.nameGuest || 'Guest'}
      </label>

      {
        invitation?.hasPartner && <label className='text-lg font-bold'>& Pasangan</label>
      }
    </>
  );
};

export default TextGuest;