const useCopyPaste = (text: string) => {

  const handleCopy = () => {
    navigator.clipboard.writeText(text)
    .then(() => {
      alert('No Rekening berhasil disalin!');
    })
    .catch((err) => {
      console.error('Gagal menyalin No Rekening: ', err);
    });
  };

  const handlePaste = () => {
    navigator.clipboard.readText()
      .then(() => {
        alert('No Rekening berhasil ditempel!');
      })
      .catch(err => {
        console.error('Gagal menempelkan No Rekening: ', err);
      });
  };

  return {handleCopy, handlePaste};
};

export default useCopyPaste;
