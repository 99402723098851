export const apiUrl = process.env.REACT_APP_API_URL;
export const mapLabel: { key: string; label: string }[] = [
  { key: 'days', label: 'Hari' },
  { key: 'hours', label: 'Jam' },
  { key: 'minutes', label: 'Menit' },
  { key: 'seconds', label: 'Detik' },
];

export const initialState = 
{
  invitation: {
    idInvitation: '',
    nameGuest: '',
    hasPartner: '',
    candidatePhoto: '',
    partnerPhoto: '',
    groomBridePhoto: '',
    nameCandidate: '',
    genderCandidate: '',
    motherNameCandidate: '',
    fatherNameCandidate: '',
    namePartner: '',
    genderPartner: '',
    motherNamePartner: '',
    fatherNamePartner: '',
    marriageCeremonyDate: '',
    marriageStartTime: '',
    marriageEndTime: '',
    marriageCeremonyBuilding: '',
    marriageCeremonyLocation: '',
    marriageCeremonyCoordinate: '',
    weddingReceptionDate: '',
    weddingStartTime: '',
    weddingEndTime: '',
    weddingReceptionsBuilding: '',
    weddingReceptionsLocation: '',
    weddingReceptionsCoordinate: ''
  },
  bankAccount: [
    {
      idEvent: '',
      bankName: '',
      accountNumber: '',
      cardHolderName: ''
    }
  ]
};
